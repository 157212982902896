
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
// import { useStore } from "vuex";
import mAxiosApi from "@/api";
import mAxios from "axios";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as moment from "moment";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import HeaderCommande from "@/views/still/commandes/headerCommande.vue";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({

  name: "customer-details",
  components: {
    Datatable,
    HeaderCommande,
    SearchAndFilter,
  },
  setup() {
    let mymap;
    const router = useRouter();
    const state = reactive({
      loaderEnabled: false,
      loadingDatatable: false,
      initialMyList: [],
      sitesList: "",
      listDates: [],
      displaySite: router.currentRoute.value.params.code ? "liste" : "carte",
      filterSite: "all",
      map: null,
      drawer: ref(false),
      showModal: false,
      idSite: 0,
      searchDefault: router.currentRoute.value.params.code ? router.currentRoute.value.params.code + "" : "",
    });
    const { t } = useI18n()

    const tableHeader = ref([
      {
        name: "Libellé",
        key: "adr_libelle",
        sortable: true,
      },
      {
        name: "Adresse",
        key: "adr_ligne_1",
        sortable: true,
      },
      {
        name: "Contact",
        key: "contacts",
        sortable: true,
      },
      {
        name: "Statut",
        key: "status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "adr_seq",
        sortable: true,
      },
    ]);

    const tableFilters = ref([]);

    const ExcelFormat = ref({
      Libellé: "adr_libelle",
      "Adresse L1": "adr_ligne_1",
      "Adresse L2": "adr_ligne_2",
      CP: "adr_cp",
      Ville: "adr_ville",
      Pays: "adr_pays",
      Contact: "contact_txt",
      Statut: "statusTxt",
    });

    onMounted(async () => {
      // INIT
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Outils de déploiement", []);
      state.loaderEnabled = false;
      const mAxiosGen = mAxios.create({
        withCredentials: false,
      });

      // MAP
      const map = L.map("mapContainer").setView([46.05, 11.05], 5);
      L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png", {
        attribution: "&copy;Stillnetwork",
      }).addTo(map);
      mymap = map;
      var customPane = map.createPane("customPane");
      customPane.style.zIndex = 399;

      // GET SITES
      let myList = await mAxiosApi.prototype.getAxios("/getSites");
      // console.log('sites: ', myList);

      let myListDates = await mAxiosApi.prototype.getAxios("/getSitesDates");

      state.listDates = myListDates.results;
      state.initialMyList = myList.results;
      state.sitesList = myList.results;
      state.loadingDatatable = true;
      state.showModal = false;
      let objUpdate = {};

      for (var i = 0; i < myList.results.length; i++) {
        const obj = myList.results[i];
        const stringAdr = encodeURI("street=" + obj.adr_ligne_1 + " " + obj.adr_ligne_2 + "&postcode=" + obj.adr_cp +  "&city=" + obj.adr_ville + "&country="+ (obj.adr_pays ? obj.adr_pays : 'france'));
        const statusVar = state.listDates.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => item.adr_seq === obj.adr_seq
        );
        _statusSite(obj, statusVar);

        if (obj.adr_lat == null || obj.adr_lat.length == 0) {
          obj.adr_lat = "";

          const response = await mAxiosGen.get(
            "https://api.geoapify.com/v1/geocode/search?" +
              stringAdr +
              "&limit=5&apiKey=1b48259b810e48ddb151889f9ea58db0"
          );

          if (response.data.features.length > 0) {
            obj.adr_lat = response.data.features[0].geometry.coordinates[1];
            obj.adr_lng = response.data.features[0].geometry.coordinates[0];

            objUpdate = {
              adr_lat: String(
                response.data.features[0].geometry.coordinates[1]
              ),
              adr_lng: String(
                response.data.features[0].geometry.coordinates[0]
              ),
            };

            await mAxiosApi.post("/updateAdresse/" + obj.adr_seq, objUpdate);
            _addMarkerMap(obj, map);
          } else {
            obj.adr_lat = "-1";
            obj.adr_lng = "-1";

            objUpdate = {
              adr_lat: String(obj.adr_lat),
              adr_lng: String(obj.adr_lat),
            };

            await mAxiosApi.post("/updateAdresse/" + obj.adr_seq, objUpdate);
            _addMarkerMap(obj, map);
          }
        } else {
          if (obj.adr_lat !== "-1") {
            _addMarkerMap(obj, map);
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.addEventListener('zanimalfound', (e: any) => modifSite(e.detail.siteId));  //console.log(e.detail.siteId)  e.detail.siteId
    });

    onBeforeUnmount(() => {
      mymap.remove();
    });

    onUnmounted(() => {
      window.removeEventListener('zanimalfound', modifSite);
    });
   
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function _addMarkerMap(obj, map) {

      var iconColor = new L.Icon({
        iconUrl:   "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-" + obj.typeMaker + ".png",
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41], iconAnchor: [12, 41],  popupAnchor: [1, -34], shadowSize: [41, 41],  });

      const buttonMap = `<div class="mb-2"><h3>${obj.adr_c_site_label === null ? '' : obj.adr_c_site_label + '<hr>'}</h3></div>
                         <div class="mb-2"><h5>${obj.adr_ligne_1}</h5></div>
                         <div class="mb-2"><h5>${obj.adr_ligne_2}</h5></div>
                         <div class="mb-2"><h5>${obj.adr_cp} - ${obj.adr_ville}</h5></div>
                         <div class="mt-10 text-primary p-3" style="box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 18%);">
                          ${obj.statusTxt}
                         </div>
                         <br>
                         <a class="btn btn-sm btn-active-secondary me-2 mt-5 btn-bg-primary btn-text-white" onclick="window.dispatchEvent(new CustomEvent('zanimalfound', {detail: {siteId: ${obj.adr_seq}}}));">
                           ${t("Modifier les détails du site")}
                         </a>`;

      L.marker([obj.adr_lat, obj.adr_lng], { icon: iconColor })
        .addTo(map)
        .bindPopup(buttonMap);
    }
    
    function addSite() {
      // createEditSiteRef.value?.addSite();
      router.push({  name: "addupdatesite", params: { code: 0 } });
    }
    
    function modifSite(idSite) {
      router.push({  name: "addupdatesite", params: { code: idSite } });
    }

    function _statusSite(obj, statusVar) {
      if (statusVar.length === 0) {
        obj.status = `
          <div class="badge badge-light-dark" >${t("Aucune livraison n'est prévue sur ce site")}</div>
        `;
        obj.statusTxt = `${t("Aucune livraison n'est prévue sur ce site")}`;
        obj.typeMaker = 'grey';
      } else {
        const mDate = moment
          .default(statusVar[0].the_date)
          .format("DD.MM.YYYY");
        const mPluriel = statusVar.length - 1 > 1 ? t("s") : "";
        let mOther =
          statusVar.length - 1 > 0
            ? ` + ${statusVar.length - 1} ${t("autre")}${mPluriel} ${t("projet")}${mPluriel}`
            : "";
        if (statusVar[0].type_recherche === "date_livraison_future") {
          obj.status = `
          <div class="badge badge-primary py-3" style="font-size: 0.900em;" >${t("Prochaine livraison prévue le")} ${mDate}</div><br / >
          <div class="badge badge-light-dark" >${t("Concerne le projet")} ${statusVar[0].pro_code} ${mOther}</div>
        `;
          obj.statusTxt = `
          ${t("Prochaine livraison prévue le")} ${mDate}. ${t("Concerne le projet")} ${statusVar[0].pro_code} ${mOther}
        `;
          obj.typeMaker = 'blue';
        } else if (statusVar[0].type_recherche === "commande_date") {
          obj.status = `
          <div class="badge badge-primary py-3" style="font-size: 0.900em;" >${t("Livraison projet")} ${statusVar[0].pro_code} (${t("Date non communiquée")})</div><br / >
          <div class="badge badge-light-dark" >${mOther}</div>
        `;
          obj.typeMaker = 'blue';
          obj.statusTxt = `
          ${t("Livraison projet")} ${statusVar[0].pro_code} (${t("Date non communiquée")}) ${mOther}
        `;
        }
      }
    }

    return {
      router,
      state,
      mymap,
      tableHeader,
      tableFilters,
      ExcelFormat,
      addSite,
      modifSite,
    };
  },
});
