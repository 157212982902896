
import { defineComponent, onMounted, reactive, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { useRouter } from "vue-router";
import mAxiosApi from "@/api";
import * as moment from "moment";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Display-commande",
  props: {
    mCode: String,
  },
  setup(props) {
    //moment.default.locale("fr");

    const store = useStore()
    const state = reactive({
      loaderEnabled: true,
      mCmd: [],
      lGed: [],
      mDoc: [],
      sourcePDF: "",
      sourceMSG: "",
      drawer: false,
      search: "",
      iframescroll: "no",
    });

    const formRef = ref<null | HTMLFormElement>(null);

    const router = useRouter();
    const { t } = useI18n()

    const mCode =
      props.mCode && props.mCode.length > 0
        ? props.mCode
        : (router.currentRoute.value.params.code as string);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `${t("La date de livraison n'a connue aucune variation depuis le")} ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, ${t("la date de livraison est passée du")} ${formatDate(
        data.date_old_ldc
      )} ${t("au")} ${formatDate(data.date_ldc)}`;
    };

    const toLogo = (logo) => {
      return "data:image/jpg;base64," + logo;
    };

    const gedPDF = async (mDoc) => {
      state.mDoc = mDoc;
      const getGedByTypeId = await mAxiosApi.prototype.getAxios("/getGedByTypeId/Seq/" + mDoc.ged_seq );
      const records = getGedByTypeId.records;
      state.iframescroll = "no";

      if (mDoc.ged_title.toUpperCase().indexOf(".PDF") > 0) {
        const srcPDF = "data:application/pdf;base64," + records[0].ged_document;
        state.sourcePDF = srcPDF;
        state.drawer = true;
        
      } else if (mDoc.ged_title.toUpperCase().indexOf(".MSG") > 0) {
        state.iframescroll = "yes";
        const srcMSG = records[0].ged_document;
        state.sourceMSG = srcMSG;
        
        state.drawer = true;
        setTimeout(() => {
          if(formRef.value){
            formRef.value.submit();
          } 
        }, 0);

      } else {
        const linkSource = `data:application/msg;base64,${records[0].ged_document}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = mDoc.ged_title;
        downloadLink.click();
      }
    };

    const iconFile = (mDoc) => {
      if (mDoc.ged_title.toUpperCase().indexOf(".PDF") > 0) {
        return "media/svg/files/pdf.svg";
      }
      if (mDoc.ged_title.toUpperCase().indexOf(".DOC") > 0) {
        return "media/svg/files/doc.svg";
      }
      return "media/svg/files/all-svg.svg";
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      let myList = await mAxiosApi.prototype.getAxios(`/getCommandes/${mCode}`);
      state.mCmd = myList.results[0];

      const item: any = state.mCmd;
      item.etat_txt = "En cours de traitement";
      if (
        item.toc_etat_livraison_min == 1 &&
        item.toc_etat_livraison_max == 2
      ) {
        item.etat_txt = "Partiellement livrée";
      }
      if (
        item.toc_etat_livraison_min == 2 &&
        item.toc_etat_livraison_max == 2
      ) {
        item.etat_txt = "Commande livrée";
      }

      let myGed = await mAxiosApi.prototype.getAxios(`/getGed/${mCode}`);
      state.lGed = myGed.results;
      setCurrentPageBreadcrumbs("Détail d'une commande", []);

      state.loaderEnabled = false;
    });
    const routeMenu = (val) => {
      return `/commande/${mCode}/${val}`;
    };

    return {
      routeMenu,
      state,
      formatDate,
      formatDateAgo,
      formatHoverDateD,
      gedPDF,
      iconFile,
      formatMoney,
      toLogo,
      formRef
    };
  },
});
